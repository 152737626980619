import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Image from 'next/image'

import { Rows, Columns, Column } from '@/components/layout'
import { Text, Link } from '@/components/common'
import routes from '@/routes'
import BuyTry from '@/components/blocks/BuyTry'
import NeatHomeList from '@/components/blocks/PricingNeatHomeList'
import quickbooksImg from '@/assets/pricing/integrations/quickbooks.png'
import hrBlockImg from '@/assets/pricing/integrations/hr-block.png'
import quickenImg from '@/assets/pricing/integrations/quicken.png'
import turbotaxImg from '@/assets/pricing/integrations/turbotax.png'
import outlookImg from '@/assets/pricing/integrations/outlook.png'
import campaignMonitorImg from '@/assets/pricing/integrations/campaign-monitor.png'
import mailchimpImg from '@/assets/pricing/integrations/mailchimp.png'
import googleContactsImg from '@/assets/pricing/integrations/google-contacts.png'
import constantContactImg from '@/assets/pricing/integrations/constant-contact.png'
import filesImg from '@/assets/pricing/list-icons/files.png'
import dataCaptureImg from '@/assets/pricing/list-icons/data-capture.png'
import expensesImg from '@/assets/pricing/list-icons/expenses.png'
import filterImg from '@/assets/pricing/list-icons/filter.png'
import fileSharingImg from '@/assets/pricing/list-icons/file-sharing.png'
import encryptionImg from '@/assets/pricing/list-icons/encryption.png'
import accessibleImg from '@/assets/pricing/list-icons/accessible.png'
import helpCenterImg from '@/assets/pricing/list-icons/help-center.png'
import unlimitedSupportImg from '@/assets/pricing/list-icons/unlimited-support.png'
import tooltipArrowImg from '@/assets/pricing/tooltip-arrow.png'

import styles from './PricingMain.module.scss'

const PricingLogos = ({ onlyNeatHomeLogos = false }) => {
  const [isTooltipShowing, setTooltipShowing] = useState(0)

  const handleQuickbooksMouseEnter = () => {
    setTooltipShowing(1)
  }

  const handleQuickbooksMouseLeave = () => {
    setTooltipShowing(0)
  }

  return (
    <Columns className={`bgcolor-brand-darker ${styles.brandlistblock}`}>
      <Column className={styles.brandtitle} size={25}>Integrates with</Column>
      <Column className={styles.brandlist} gap={8} size={75}>
        {!onlyNeatHomeLogos &&
        <div
          className={styles.quickbooksHolder}
          onMouseEnter={handleQuickbooksMouseEnter}
          onMouseLeave={handleQuickbooksMouseLeave}
        >
          <Image alt="Intuit Quickbooks" src={quickbooksImg} width={146} />
          <div className={`${!isTooltipShowing && styles.hide} ${styles.tooltip}`}>
            <Image
              alt=""
              className={styles.tooltipArrow}
              src={tooltipArrowImg}
            />
            <ul>
              <li>Review & send all expense-related data to QuickBooks. Images of receipts and invoices are automatically attached.</li>
              <li>Sign in to QuickBooks from the Neat app to connect.</li>
              <li>Map expenses once to match your QuickBooks preferences and future data will map automatically.</li>
            </ul>
          </div>
        </div>}

        <Image alt="H&R Block" src={hrBlockImg} width={125} />

        <Image alt="Quicken" src={quickenImg} width={117} />

        <Image alt="Intuit TurboTax" src={turbotaxImg} width={138} />

        <Image alt="Microsoft Outlook" src={outlookImg} width={144} />

        {!onlyNeatHomeLogos &&
        <>
          <Image alt="Campaign Monitor" src={campaignMonitorImg} width={178} />

          <Image alt="Mailchimp" src={mailchimpImg} width={109} />
        </>}

        <Image alt="Google Contacts" src={googleContactsImg} width={121} />

        {!onlyNeatHomeLogos && <Image alt="Constant Contact" src={constantContactImg} width={202} />}
      </Column>
    </Columns>
  )
}

PricingLogos.propTypes = {
  onlyNeatHomeLogos: PropTypes.bool,
}

const PricingMain = ({
  hideLogos,
  hasBottomContent,
  learnMoreBt = true,
  canTry,
  price = '$200/year',
  buyCtaMessageId,
  testIdPrefix,
  promocode,
  strikeThrough,
  tryCtaMessageId = 'cta.tryNeatFree',
  showNeatHomeList = false,
  onlyNeatHomeLogos = false,
  descriptionLineOne = '',
  descriptionLineTwo = 'Start organizing today.',
  planTitle = '',
  isVip = false,
  buySku,
  trySku,
}) => {
  let planName = isVip ? 'Neat + VIP Services' : 'Neat'
  let planDescription = isVip
    ? 'Our primary platform for file document management with dedicated VIP support from our experts.'
    : 'Our primary platform for file document management.'
  const helpCenterBlurb = isVip ? 'Dedicated support team so you can skip the line' : 'Access to our Help Center Knowledge Base'
  const supportBlurb = isVip ? '1:1 training guided by Neat Experts' : 'Unlimited email & chat support'
  planName = planTitle || planName
  planDescription = descriptionLineOne || planDescription

  return (
    <Rows
      className={cx(styles.wrapper, hideLogos && styles.hideLogos, hasBottomContent && styles.hasBottomContent)}
      gap={0}
    >
      <Columns gap={0}>
        <Column bgPrimary className={styles.pricingcolumn} size={50}>
          <Rows gap={2}>
            <Text.H3 className={styles.plan} responsive={false}>{planName}</Text.H3>
            <Text.P>{planDescription}<br />{descriptionLineTwo}</Text.P>
          </Rows>
          <div className={styles.divider}>&nbsp;</div>
          <Rows gap={24}>
            <Text.H3 brandPrimary className={styles.price} responsive={false}>
              {strikeThrough && <span className={styles.strikeThrough}>{strikeThrough}</span>}{price}
            </Text.H3>
            <BuyTry
              buyCtaMessageId={buyCtaMessageId}
              buySku={isVip ? 'neatvipyr' : buySku}
              canTry={canTry}
              className={styles.buytry}
              justifyStart
              noOutline
              promocode={promocode}
              testIdPrefix={testIdPrefix}
              tryCtaMessageId={tryCtaMessageId}
              trySku={trySku}
              withCustomBreakpoint
            />
            {learnMoreBt && <Link className={styles.learnmore} to={routes.neatFeatures}>Learn more about Neat →</Link>}
          </Rows>
        </Column>
        <Column bgBrand className={styles.featurescolumn} size={50}>
          {showNeatHomeList ? (
            <NeatHomeList />
          ) : (
            <ul>
              <li>
                <Image alt="" src={filesImg} />
                Unlimited file storage
              </li>
              <li>
                <Image alt="" src={dataCaptureImg} />
                Data capture with human verification
              </li>
              <li>
                <Image alt="" src={expensesImg} />
                Track your expenses
              </li>
              <li>
                <Image alt="" src={filterImg} />
                Easily filter and search for documents
              </li>
              <li>
                <Image alt="" src={fileSharingImg} />
                File sharing and collaboration
              </li>
              <li>
                <Image alt="" src={encryptionImg} />
                Bank-level security encryption
              </li>
              <li>
                <Image alt="" src={accessibleImg} />
                Accessible from anywhere
              </li>
              <li>
                <Image alt="" src={helpCenterImg} />
                {helpCenterBlurb}
              </li>
              <li>
                <Image alt="" src={unlimitedSupportImg} />
                {supportBlurb}
              </li>
            </ul>
          )}
        </Column>
      </Columns>
      {!hideLogos && <PricingLogos onlyNeatHomeLogos={onlyNeatHomeLogos} /> }
    </Rows>
  ) }

PricingMain.propTypes = {
  hasBottomContent: PropTypes.bool,
  learnMoreBt: PropTypes.bool,
  canTry: PropTypes.bool,
  hideLogos: PropTypes.bool,
  showNeatHomeList: PropTypes.bool,
  onlyNeatHomeLogos: PropTypes.bool,
  buyCtaMessageId: PropTypes.string,
  tryCtaMessageId: PropTypes.string,
  strikeThrough: PropTypes.string,
  planTitle: PropTypes.string,
  descriptionLineOne: PropTypes.string,
  descriptionLineTwo: PropTypes.string,
  price: PropTypes.string,
  promocode: PropTypes.string,
  testIdPrefix: PropTypes.string,
  buySku: PropTypes.string,
  trySku: PropTypes.string,
  isVip: PropTypes.bool,
}

export default PricingMain
