import React from 'react'
import Image from 'next/image'

import filesImg from '@/assets/pricing/list-icons/files.png'
import expensesImg from '@/assets/pricing/list-icons/expenses.png'
import filterImg from '@/assets/pricing/list-icons/filter.png'
import fileSharingImg from '@/assets/pricing/list-icons/file-sharing.png'
import accessibleImg from '@/assets/pricing/list-icons/accessible.png'
import helpCenterImg from '@/assets/pricing/list-icons/help-center.png'
import unlimitedSupportImg from '@/assets/pricing/list-icons/unlimited-support.png'
import bankAccountCreation from '@/assets/pricing/list-icons/bank-account-creation.png'
import generateReports from '@/assets/pricing/list-icons/generate-reports.png'
import transationStreaming from '@/assets/pricing/list-icons/transaction-streaming.png'

const PricingNeatHomeList = () => (
  <ul>
    <li>
      <Image alt="" src={filesImg} />
      Unlimited file storage
    </li>
    <li>
      <Image alt="" src={bankAccountCreation} />
      Bank Account Connection
    </li>
    <li>
      <Image alt="" src={transationStreaming} />
      Transaction Streaming
    </li>
    <li>
      <Image alt="" src={generateReports} />
      Quickly Generate Reports
    </li>
    <li>
      <Image alt="" src={expensesImg} />
      Track your expenses
    </li>
    <li>
      <Image alt="" src={filterImg} />
      Easily filter and search for documents
    </li>
    <li>
      <Image alt="" src={fileSharingImg} />
      Bank-level security encryption
    </li>
    <li>
      <Image alt="" src={accessibleImg} />
      Accessible from anywhere
    </li>
    <li>
      <Image alt="" src={helpCenterImg} />
      Access to our Help Center Knowledge Base
    </li>
    <li>
      <Image alt="" src={unlimitedSupportImg} />
      Unlimited email & chat support
    </li>
  </ul>
)

export default PricingNeatHomeList
